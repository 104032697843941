import Box from 'components/commom/Box';
import Button from 'components/commom/Button';
import Typography from 'components/commom/Typography';
import * as S from './styles';
import iphone from "./img/iphone.svg";

function ContedBooks() {
  return (
    <Box bg='primary'>
      <Box boxMain>
        <Box flexDirection='column' gap={50}>
          <Box flexDirection='row' flexDirectionMobile='column' alignItems='center' alignItemsMob='flex-start' gap={50}>
            <Box flexDirection='column' gap={20}>
              <Typography variant='title' fontSize='lg' color='primary' noWrap>
                Biblioteca S
              </Typography>
              <Typography color='light' variant='body' noWrap>
                A Biblioteca S é uma vasta e exclusiva coleção digital que disponibiliza livros, artigos, revistas científicas, base de dados e outros materiais
                que atendem à bibliografia de mais de 50 cursos de graduação, inúmeros cursos técnicos e de pós-graduação.
              </Typography>
              <Typography color='light' variant='body' noWrap>
                Além do objetivo central de preservação do conhecimento e transformar o cenário acadêmico brasileiro, a Biblioteca S também é um facilitador
                para instituições de ensino científico, devido à praticidade em integrar com qualquer plataforma LMS (Learning Management System, ou Sistema de
                Gestão de Aprendizagem) e a sua utilidade para elaboração da bibliografia de um PPC (Projeto Pedagógico do Curso).
              </Typography>
              <Typography color='light' variant='body' noWrap>
                Todos os materiais disponíveis na biblioteca atendem aos critérios exigidos pelo MEC (Ministério da Educação) e abrangem diversas áreas do
                conhecimento, como saúde, direito, tecnologia, negócios, educação, meio ambiente, entre outros.
              </Typography>
            </Box>
            <Box flexDirection='column' gap={12}>
              <S.Image src={iphone} alt='Biblioteca S' />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ContedBooks;
