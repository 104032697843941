import Box from 'components/commom/Box';
import Button from 'components/commom/Button';
import Typography from 'components/commom/Typography';
import * as S from './styles';

export type BookComponentProps = {
  id?: string;
  photo: string;
  name: string;
  author?: string;
  title?: string;
  width?: string;
  pdfFile: string;
  onClick?: () => void;
};

const Book: React.FC<BookComponentProps> = ({ photo, name, author, onClick, title, width }) =>{
  return (
    <S.BookWrap onClick={onClick}>
      <S.Book>
        <Box flexDirection='column' alignItems='center' gap={12}>
          <img width='171px' src={photo} alt='book' />
          <div style={{width: `${width}`}} title={title}>
            <Typography  variant='body' color='light' weight='bold' align='center' fill noWrap>
              {name}
            </Typography>
          </div>
          <Typography variant='body' fontSize='sm' color='light' weight='regular' align='center' fill noWrap>
            {author}
          </Typography>
        </Box>
      </S.Book>
    </S.BookWrap>
  );
}

export default Book;
