import LayoutDefault from 'components/Layouts/LayoutDefault/component';
import Banner from './Sections/BannerSection/component';
import CarouselCards from './Sections/CarouselCards/component';
import InfoSection from './Sections/InfoSection/component';
import ContedBooks from './Sections/ContedBooks/component';
import AlternateCards from './Sections/AlternateCards/component';

function BibliotecaS() {
  return (
    <LayoutDefault>
      <Banner />
      <InfoSection />
      <ContedBooks />
      <CarouselCards />
      <AlternateCards />
    </LayoutDefault>
  );
}
export default BibliotecaS;
