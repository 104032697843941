import React from 'react';
import Box from 'components/commom/Box';
import Button from 'components/commom/Button';
import Typography from 'components/commom/Typography';
import TypographyMobile from 'components/commom/Typography';
import * as S from './styles';

type BookModalProps = {
  isOpen: boolean;
  onClose: () => void;
  book: {
    photo: string;
    pdfFile: string;
    title?: string;
    author?: string;
    description?: string;
  } | null;
};

const BookModal: React.FC<BookModalProps> = ({ isOpen, onClose, book }) => {
  if (!isOpen || !book) return null;

  return (
    <S.ModalOverlay>
      <S.ModalContent>
        <Box flexDirection='column' gap={12}>
          <S.Box>
            <img width='300px' src={book.photo} alt='book' />
            <S.BoxText>
              <Typography variant='body' color='light' weight='bold' align='left' fill noWrap>
                {book.title}
              </Typography>
              <Typography variant='body' color='light' weight='regular' align='left' fill noWrap>
                {book.author}
              </Typography>
              <S.Description>
                {book.description}
              </S.Description>
            </S.BoxText>
          </S.Box>
          <S.BoxButtons>
            <Button neonEffect={true} size='small' onClick={() => window.open(book.pdfFile, '_blank')} text='Ver PDF' />
            <Button neonEffect={true} size='small' onClick={onClose} text='Fechar' />
          </S.BoxButtons>
        </Box>
      </S.ModalContent>
    </S.ModalOverlay>
  );
};

export default BookModal;
