import Box from 'components/commom/Box';
import Button from 'components/commom/Button';
import Typography from 'components/commom/Typography';
import * as S from './styles';
import Rectangle1 from './img/rectangle1.png';
import Rectangle2 from './img/rectangle2.png';
import Rectangle3 from './img/rectangle3.png';

function AlternateCardsSection() {
  return (
    <Box bg='primary'>
      <Box boxMain>
        <Box flexDirection='column' gap={50}>
          <S.BoxTitle>
            <Typography variant='title' fontSize='lg' color='primary' wrap>
              Como funciona a plataforma?
            </Typography>
          </S.BoxTitle>
          <Box flexDirection='row' flexDirectionMobile='column' alignItems='center' alignItemsMob='flex-start' gap={50}>
            <Box flexDirection='column' gap={20}>
              <Typography color='primary' fontSize='md' noWrap>
                DIGITAL E RESPONSIVA
              </Typography>
              <Typography color='light' variant='body' noWrap>
                A Biblioteca S é 100% digital e responsiva, ou seja, pode ser acessada por qualquer dispositivo sem problemas, além de ser facilmente integrável
                com qualquer plataforma LMS.
              </Typography>
            </Box>
            <Box flexDirection='column' gap={12}>
              <S.Image src={Rectangle1} alt='Biblioteca S' />
            </Box>
          </Box>
          <Box flexDirection='row' flexDirectionMobile='column-reverse' alignItems='center' alignItemsMob='flex-start' gap={50}>
            <Box flexDirection='column' gap={12}>
              <S.Image src={Rectangle2} alt='Biblioteca S' />
            </Box>
            <Box flexDirection='column' gap={20}>
              <Typography color='primary' fontSize='md' noWrap>
                PARA A SUA INSTITUIÇÃO
              </Typography>
              <Typography color='light' variant='body' noWrap>
                Ela está estruturada para atender às necessidades de docentes e instituições de ensino, funcionando como um apoio estratégico na construção de
                projetos pedagógicos inovadores e alinhados com as demandas educacionais contemporâneas.
              </Typography>
            </Box>
          </Box>
          <Box flexDirection='row' flexDirectionMobile='column' alignItems='center' alignItemsMob='flex-start' gap={50}>
            <Box flexDirection='column' gap={20}>
              <Typography color='primary' fontSize='md' noWrap>
                EQUIPE QUALIFICADA
              </Typography>
              <Typography color='light' variant='body' noWrap>
                Contamos com uma equipe diversificada de profissionais altamente qualificados, incluindo editores, diagramadores, programadores, designers
                instrucionais e professores encarregados da criação e desenvolvimento de todos os nossos conteúdos. Portanto, a Biblioteca S se posiciona como
                uma parceira essencial para a consolidação de uma educação de excelência e para o fortalecimento da ciência nacional.
              </Typography>
            </Box>
            <Box flexDirection='column' gap={12}>
              <S.Image src={Rectangle3} alt='Biblioteca S' />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default AlternateCardsSection;
