import Box from 'components/commom/Box';
import Button from 'components/commom/Button';
import Typography from 'components/commom/Typography';
import * as S from './styles';
import { useEffect, useRef, useState } from 'react';
import Book, { BookComponentProps } from './components/Book';
import Book1 from './img/administracao_financeira.jpg';
import Book2 from './img/administracao_para_micro.jpg';
import Book3 from './img/ensino_hibrido.jpg';
import Book4 from './img/fundamentos_pedagógicos.jpg';
import Book5 from './img/historia_da_educacao.jpg';
import Book6 from './img/lingua_portuguesa.jpg';
import Book7 from './img/marketing_e_sociedade.jpg';
import Book8 from './img/matematica_e_raciocinio.jpg';
import Book9 from './img/mercado_digital.jpg';
import Book10 from './img/sociologia_moderna.jpg';
import Slider from 'react-slick';
import BookModal from '../../../../components/commom/Modal/component';

function ContedBooks() {
  const [selectedBook, setSelectedBook] = useState<BookComponentProps | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const items = [
    {
      id: '1',
      name: 'Administração Financeira',
      title: 'Administração Financeira',
      photo: `${Book1}`,
      author: 'Robson Cesar Santos',
      pdfFile: `assets/pdf/administracao_financeira.pdf`,
      description: `O livro sobre Administração Financeira escrito por Robson Cesar Santos, a obra abrange tópicos essenciais de administração financeira, incluindo decisões de investimento, financiamento e resultado, gestão de empresas familiares, planejamento e controle financeiro, análise de demonstrações contábeis, e o sistema financeiro nacional.  O livro também explora o valor do dinheiro no tempo, diferentes métodos de amortização, e análise de indicadores econômico-financeiros.  Finalmente, são apresentadas discussões sobre o papel do Estado na economia e a estrutura dos mercados.`,
    },
    {
      id: '2',
      name: 'Administração para Micro...',
      title: 'Administração para Micro e Médias Empresas',
      photo: `${Book2}`,
      author: 'Deise Leia Farias Hofmeister',
      pdfFile: `assets/pdf/administracao_para_micro.pdf`,
      description: `O livro "Administração para Micro e Médias Empresas" é um livro que aborda a administração de empresas de pequeno e médio porte, focando em logística de mercado, administração financeira e marketing digital.  A obra oferece conceitos teóricos e práticos, incluindo ferramentas como a matriz BCG e as cinco forças de Porter, para auxiliar gestores na tomada de decisões estratégicas e operacionais. A autora, Deise Leia Farias Hofmeister, apresenta uma visão integrada da gestão, enfatizando a importância da otimização de processos, redução de custos e foco em resultados para alcançar competitividade.  O livro também destaca a crescente importância do marketing digital e da gestão da cadeia de suprimentos no contexto atual.`,
    },
    {
      id: '3',
      name: 'Ensino Híbrido e Motivação',
      title: 'Ensino Híbrido e Motivação',
      photo: `${Book3}`,
      author: 'Hikaro Queiroz e Mikael Ribeiro',
      pdfFile: `assets/pdf/ensino_hibrido.pdf`,
      description: `O documento "Ensino Híbrido e Motivacional" é um livro eletrônico que explora o ensino híbrido, definindo-o, detalhando suas metodologias (rotação por estações, laboratório rotacional, sala de aula invertida, rotação individual, flex, à la carte e virtual enriquecido), vantagens (autonomia, colaboração, otimização de tempo, adequação de metodologias e personalização), e ferramentas tecnológicas (Planboard, GoConqr, MindMeister, Canva, YouTube, Zoom, Edmodo, AprendiZap, Prova Fácil e Google Classroom).  O livro também discute o papel do professor na transição para o ensino híbrido e a importância da autonomia do aluno, citando autores como Freire e outros, para fundamentar as reflexões sobre a prática pedagógica transformadora. Finalmente, aborda a relação entre educação e autonomia, enfatizando a importância da formação crítica e reflexiva do aluno.`,
    },
    {
      id: '4',
      name: 'Fundamentos pedagógicos...',
      title: 'Fundamentos pedagógicos dos esportes e das atividades físicas',
      photo: `${Book4}`,
      author: 'Leandro Lemes',
      pdfFile: `assets/pdf/fundamentos_pedagogicos.pdf`,
      description: `O livro "Fundamentos Pedagógicos dos Esportes e das Atividades Físicas" apresenta uma abordagem completa da Educação Física escolar, explorando sua história no Brasil, conceitos fundamentais, e diferentes metodologias de ensino.  Aborda a pedagogia do esporte, a importância da atividade física em diversas faixas etárias e níveis de habilidade, e a influência da mídia.  O texto detalha a caracterização e classificação de esportes e jogos, oferecendo exemplos práticos de jogos reduzidos para o ensino de esportes como futsal, basquetebol, handebol e voleibol, além de atletismo e ginásticas, alinhados com as habilidades da BNCC.  Finalmente, destaca a Carta Internacional da Educação Física, da Atividade Física e do Esporte da UNESCO.`,
    },
    {
      id: '5',
      name: 'História da educação',
      title: 'História da educação',
      photo: `${Book5}`,
      author: 'Lúcia Soares da Silva',
      pdfFile: `assets/pdf/historia_da_educacao.pdf`,
      description: `O livro apresenta um compêndio de excertos de "História da Educação", cobrindo diversos períodos históricos da educação, desde a antiguidade clássica até o século XX.  A obra analisa diferentes sistemas educacionais, incluindo o da Era Vargas no Brasil, o movimento da Escola Moderna, a Escola Nova, o Iluminismo, a educação jesuítica no Brasil colonial e o impacto da Reforma Protestante.  A autora, Inajara Pires de Souza, é socióloga e professora com vasta experiência em estudos sobre educação e direitos humanos. O foco é a evolução das práticas e ideologias educacionais ao longo da história, evidenciando as relações entre educação e poder político e social.`,
    },
    {
      id: '6',
      name: 'Língua Portuguesa...',
      title: 'Língua Portuguesa Descomplicada',
      photo: `${Book6}`,
      author: 'Mikael Ribeiro',
      pdfFile: `assets/pdf/lingua_portuguesa.pdf`,
      description: `O livro apresenta um estudo abrangente da língua portuguesa, focando na morfossintaxe, sintaxe, e análise de termos oracionais essenciais, integrantes e acessórios.  Explora-se a relação entre morfologia e sintaxe, detalhando as funções sintáticas e a classificação de orações.  Finalmente, discute-se o preconceito linguístico na escola, a importância do ensino das variações linguísticas, e a necessidade de metodologias inclusivas e que valorizem a diversidade linguística.`,
    },
    {
      id: '7',
      name: 'Marketing e Sociedade',
      title: 'Marketing e Sociedade',
      photo: `${Book7}`,
      author: 'Lorene Paixão Sampaio',
      pdfFile: `assets/pdf/marketing_e_sociedade.pdf`,
      description: `O livro "Marketing e Sociedade", de Lorene Paixão Sampaio, explora conceitos fundamentais de marketing, desde seus objetivos e estratégias até tendências contemporâneas como marketing digital, neuromarketing e marketing social.  A obra analisa o comportamento do consumidor, a importância da comunicação integrada, e a aplicação do marketing em diferentes contextos, incluindo o ambiente digital e o social.  São apresentadas diversas ferramentas e estratégias de marketing, com exemplos práticos e referências teóricas.  Finalmente, o livro discute o marketing 5.0 e sua relação com o marketing social e societal.`,
    },
    {
      id: '8',
      name: 'Matemática e raciocínio lógico',
      title: 'Matemática e raciocínio lógico',
      photo: `${Book8}`,
      author: 'Vinicius Valdivia Hernandez',
      pdfFile: `assets/pdf/matematica_e_raciocinio.pdf`,
      description: `O livro de Matemática e Raciocínio Lógico, aborda tópicos como teoria dos conjuntos, relações entre proposições, operações lógicas e com conjuntos, regra de três, porcentagem, e funções de primeiro e segundo grau.  A obra utiliza diagramas de Venn e tabelas-verdade para ilustrar conceitos, e explora exemplos práticos para aplicar os temas abordados.  A linguagem é acessível, incluindo definições, propriedades e exemplos detalhados, com foco na aplicação prática dos conceitos matemáticos. O livro é estruturado em unidades, cada uma dedicada a um tópico específico com exemplos resolvidos.  Finalmente, a publicação inclui informações sobre o autor e sua experiência acadêmica e profissional em matemática.`,
    },
    {
      id: '9',
      name: `Mercado digital`,
      title: `Mercado digital`,
      photo: `${Book9}`,
      width: '80%',
      author: 'Sandra Patricia Éder Comandulli',
      pdfFile: `assets/pdf/mercado_digital.pdf`,
      description: `O livro "Mercado Digital" explora a transformação digital no mercado, focando na experiência do consumidor e nas estratégias de marketing digital.  Aborda a evolução da internet, desde a Web 1.0 até a Web 4.0, analisando as necessidades virtuais e as tecnologias emergentes como inteligência artificial e metaverso.  Discute o marketing de atração (inbound), o funil de vendas, a importância do conteúdo digital, e a experiência do usuário (UX), além de estratégias de propaganda em redes sociais e a integração do mundo físico e virtual.  Finalmente, examina tecnologias visionárias e suas implicações para os negócios e a sociedade.`,
    },
    {
      id: '10',
      name: 'Sociologia moderna',
      title: 'Sociologia moderna',
      photo: `${Book10}`,
      author: 'Camilla de Almeida Silva',
      pdfFile: `assets/pdf/sociologia_moderna.pdf`,
      description: `O livro "Sociologia Moderna" de Camilla de Almeida Silva, que explora a sociologia, sua história e principais teóricos (Comte, Durkheim, Marx e Weber), abordando conceitos como modernidade, individualismo, funcionalismo, racionalização, e a relação entre capitalismo e trabalho. Além disso, discute a sociologia das organizações, cultura organizacional, e o impacto do avanço tecnológico nas relações sociais e no trabalho.  O livro também analisa a sociedade do consumo e os dilemas da modernidade, incluindo a religião.`,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleBookClick = (book: BookComponentProps) => {
    setSelectedBook(book);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedBook(null);
  };

  return (
    <Box bg='primary'>
      <Box boxMain>
        <Box width='100%' flexDirection='column' gap={50}>
          <Box flexDirection='row' flexDirectionMobile='column' alignItems='center' alignItemsMob='flex-start' gap={50}>
            <Box flexDirection='column' gap={12}>
              <Typography variant='title' fontSize='lg' color='primary' noWrap>
                Alguns de nossos livros:
              </Typography>
            </Box>
          </Box>
          <S.BooksWrap>
            <Slider {...settings}>
              {items.map((item) => (
                <div key={item.id}>
                  <Book {...item} onClick={() => handleBookClick(item)} />
                </div>
              ))}
            </Slider>
          </S.BooksWrap>
        </Box>
      </Box>
      <BookModal isOpen={isModalOpen} onClose={handleCloseModal} book={selectedBook} />
    </Box>
  );
}
export default ContedBooks;

interface ArrowProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const NextArrow: React.FC<ArrowProps> = (props) => {
  const { className, onClick } = props;
  return <div className={`slick-arrow ${className}`} onClick={onClick} />;
};

const PrevArrow: React.FC<ArrowProps> = (props) => {
  const { className, onClick } = props;
  return <div className={`slick-arrow ${className}`} onClick={onClick} />;
};
