import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
`;

export const ModalContent = styled.div`
  background: #333;
  padding: 20px;
  border-radius: 8px;
  max-width: 1000px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
    max-width: 90%;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  gap: 62px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    > img {
      width: 150px;
    }
  }
`;
export const Description = styled.div`
    font-size: 1.5rem;
    color: #fff;
  font-family: Light;

  @media (max-width: 768px) {
    font-size: 1rem;
    color: #fff;
  }
`;