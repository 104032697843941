import styled from 'styled-components';

export const BookWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  max-width: 187px;
`;
export const Book = styled.div`
  width: 100%;
  max-width: 187px;
`;
