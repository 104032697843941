import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 3.125rem;

  > div {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 898px;
    }
  }

  & button {
    display: none;
  }
`;
export const BoxTitle = styled.div`
  max-width: 898px;
  margin: 0 40px 63px;
  @media (min-width: 1024px) {
    margin: 0 auto 63px;
    }
`;
