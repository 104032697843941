import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import Logo from './icons/logo.png';
import Bars from './icons/bars.svg';
import Close from './icons/close.svg';
import { useState } from 'react';

function Navbar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <S.Navbar>
      <S.NavbarWrap>
        <S.LogoWrap  onClick={() => navigate('/')} open={open}>
          <img src={Logo} alt="Logo" />
        </S.LogoWrap>
        <S.IconWrap onClick={() => setOpen(!open)}>
          <ReactSVG src={open ? Close : Bars} />
        </S.IconWrap>
        <S.LinkContainer open={open}>
          <S.LinksWrap onClick={() => setOpen(false)}>
            <li onClick={() => navigate('/about')}>Quem somos</li>
            <li onClick={() => navigate('/ecosystem')}>Plataforma s</li>
            <li onClick={() => navigate('/materials')}>Material Didático</li>
            <li onClick={() => navigate('/bibliotecas')}>Biblioteca S</li>
            <li onClick={() => window.open('/blog','_blank')}>Blog</li>
          </S.LinksWrap>
        </S.LinkContainer>
      </S.NavbarWrap>
    </S.Navbar>
  );
}
export default Navbar;
