import styled from 'styled-components';

export const BooksWrap = styled.div`
  width: 100%;
  max-width: 898px;
  margin: 0 auto;
  padding: 0 1.25rem;
  overflow: hidden;
    > div {
      height: 360px; 
    }
`;
export const FlexBooks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3.125rem;
`;
