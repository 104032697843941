import styled from 'styled-components';

export const Card = styled.div`
  width: 270px;
  min-height: 311px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bg.secondary};
  border-radius: 15px;
  padding: 3.125rem 1.563rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3.5rem;

  & h4 {
    font-size: 1.375rem !important;
  }
`;

export const IconWrap = styled.div`
  & div {
    height: 60px;
  }
`;
