import Box from 'components/commom/Box';
import Carousel from 'components/utils/Carousel/component';
import CarouselItem from './CarouselItem/component';
import * as S from './styles';
import People from './icons/people.svg';
import Document from './icons/document.svg';
import Selo from './icons/selo.svg';
import MoneyManagement from './icons/money-management.svg';
import Computer from './icons/computer.svg';
import Cellphone from './icons/cellphone.svg';
import Button from 'components/commom/Button/component';
import Typography from 'components/commom/Typography';

function CarouselCards() {
  return (
    <Box flex={false} bg='primary'>
      <S.Container>
        <S.BoxTitle>
          <Typography alignDesktop='center' variant='title' fontSize='lg' color='primary' noWrap>
            Nossos Diferenciais:
          </Typography>
        </S.BoxTitle>
        <Carousel
          noCarousel
          items={[
            <CarouselItem icon={People} title='Auxílio da nossa equipe para elaboração da bibliografia do seu PPC.' />,
            <CarouselItem icon={Document} title='Referenciais bibliográficos para mais de 50 graduações.' />,
            <CarouselItem icon={Selo} title='Todos os materiais atendem aos critérios exigidos pelo MEC.' />,
            <CarouselItem icon={MoneyManagement} title='Cobrança pelo uso da biblioteca, e não pela quantidade de alunos.' />,
            <CarouselItem icon={Computer} title='Navegação simples e intuitiva.' />,
            <CarouselItem icon={Cellphone} title='+3.000 livros disponíveis.' />,
          ]}
        />
      </S.Container>
      <Box justifyContent='center'>
        <Button
          neonEffect={true}
          onClick={() => {
            window.open('https://wa.me/5511917927702', '_blank');
          }}
          text='Quero Conhecer a Biblitoeca S'
          variant='primary'
        />
      </Box>
    </Box>
  );
}

export default CarouselCards;
