import Box from 'components/commom/Box';
import Typography from 'components/commom/Typography';
import * as S from './styles';
import Wpp from './icons/wpp.svg';
import Agenda from './icons/agenda.svg';
import Linkedin from './icons/linkedin.svg';
import Instagram from './icons/instagram.svg';
import Youtube from './icons/youtube.svg';
import { ReactSVG } from 'react-svg';
import Button from 'components/commom/Button';
import { useState } from 'react';
import axios from 'axios';

function PageForm() {
  const [inputs, setInputs] = useState({
    talk: '',
  });
  const [isLoad, setLoad] = useState(false);
  function redirect(url: string) {
    window.open(url, '_blank');
  }

  function handleInputChange(event: any) {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  }

  async function submitForm(event: any) {
    event.preventDefault();
    try {
      setLoad(true);
      const { status } = await axios.post(
        'https://backoffice.conted.tech/api/sendTo',
        inputs
      );
      if (status === 200) {
        alert('Recebemos seus dados, logo entraremos em contato!');
      }
    } catch (error) {
      console.log(error);
      alert('Não foi possivel enviar os dados!');
    } finally {
      setLoad(false);
    }
  }

  return (
    <Box bg="primary">
      <Box boxMain flex={false}>
        <Box flexDirection="column" gap={50}>
          {/* <S.TitleWrap>
            <Typography variant="title" color="primary" noWrap>
              Entre em Contato com a Nossa Equipe
            </Typography>
          </S.TitleWrap> */}

          <Box flexDirection="column" gap={25}>
            <Typography
              variant="body"
              color="light"
              fill
              align="center"
              noWrap
              weight="black"
              fontSize="md"
            >
              Horário de Funcionamento
            </Typography>
            <Typography variant="body" color="light" fill align="center" noWrap>
              Segunda a Sexta - 08h às 18h
            </Typography>
          </Box>

          <S.SocialsWrap>
            <S.Socials
              onClick={() =>
                redirect('https://wa.me/5511917927702')
              }
            >
              <S.IconWrap>
                <ReactSVG src={Wpp} />
              </S.IconWrap>
              <Typography color="light" variant="body" weight="bold" noWrap>
                WhatsApp
              </Typography>
            </S.Socials>
            <S.Socials
              onClick={() =>
                redirect('https://www.youtube.com/@laboratorio_ead')
              }
            >
              <S.IconWrap>
                <ReactSVG src={Youtube} />
              </S.IconWrap>
              <Typography color="light" variant="body" weight="bold" noWrap>
                Youtube
              </Typography>
            </S.Socials>
            <S.Socials
              onClick={() =>
                redirect('https://calendly.com/hikaro-q3q/30min?back=1&month=2023-12')
              }
            >
              <S.IconWrap>
                <ReactSVG src={Agenda} />
              </S.IconWrap>
              <Typography color="light" variant="body" weight="bold" noWrap>
                Agenda
              </Typography>
            </S.Socials>
            <S.Socials
              onClick={() => redirect('https://www.instagram.com/conted.tech/')}
            >
              <S.IconWrap>
                <ReactSVG src={Instagram} />
              </S.IconWrap>
              <Typography color="light" variant="body" weight="bold" noWrap>
                Instagram
              </Typography>
            </S.Socials>
            <S.Socials
              onClick={() =>
                redirect('https://www.linkedin.com/company/contedtech/')
              }
            >
              <S.IconWrap>
                <ReactSVG src={Linkedin} />
              </S.IconWrap>
              <Typography color="light" variant="body" weight="bold" noWrap>
                Linkedin
              </Typography>
            </S.Socials>
          </S.SocialsWrap>
          {/* <Box flexDirection="column" alignItems="center">
            <S.Form id="form" onSubmit={submitForm}>
              <Typography
                color="dark"
                noWrap
                variant="h3"
                fill
                align="center"
                weight="black"
              >
                 Posso te ajudar<span>?</span> 
              </Typography>
              <Box flexDirection="column" gap={16}>
                <S.GridInputs>
                  <S.InputsWrap>
                    <label>Nome</label>
                    <S.Input
                      required
                      onChange={handleInputChange}
                      name="name"
                    />
                  </S.InputsWrap>
                  <S.InputsWrap>
                    <label>Email</label>
                    <S.Input
                      required
                      onChange={handleInputChange}
                      name="email"
                    />
                  </S.InputsWrap>
                  <S.InputsWrap>
                    <label>Telefone</label>
                    <S.Input
                      required
                      onChange={handleInputChange}
                      name="phone"
                    />
                  </S.InputsWrap>
                  <S.InputsWrap>
                    <label>Cidade/Estado</label>
                    <S.Input
                      required
                      onChange={handleInputChange}
                      name="state"
                    />
                  </S.InputsWrap>
                  <S.InputsWrapLast>
                    <label>Assunto</label>
                    <S.Select required onChange={handleInputChange} name="talk">
                      <option value="">Selecione</option>
                      <option value="Publique conosco">Publique conosco</option>
                      <option value="Material didático">
                        Material didático
                      </option>
                      <option value="Plataforma S">Plataforma S</option>
                      <option value="Kit MEC">Kit MEC</option>
                      <option value="Professor Conteudista">
                        Professor Conteudista
                      </option>
                      <option value="Compra de livro">Compra de livro</option>
                    </S.Select>
                  </S.InputsWrapLast>
                </S.GridInputs>
                <S.InputsWrap>
                  <label>Mensagem</label>
                  <S.TextArea
                    onChange={handleInputChange}
                    name="message"
                    rows={8}
                    required
                  />
                </S.InputsWrap>
              </Box>
              <Box justifyContent="flex-end">
                <Button
                  disabled={isLoad}
                  text={isLoad ? 'Enviando' : 'Enviar'}
                  variant="secondary"
                />
              </Box>
            </S.Form>
          </Box>*/}
        </Box>
      </Box>
    </Box>
  );
}
export default PageForm;
